import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    fetchBanners,
    fetchHomeBigDiscounts,
    fetchHomeFeatureBrands,
    fetchHomeFeatureCategories,
    fetchHomeFlashDeals,
    fetchHomeNewArrivals,
    fetchHomeServices,
    fetchHomeSliders,
    fetchHomeTopCategories,
    fetchHomeTopRatings,
    fetchMoreForYou
} from "../helpers/backend_helper";
import {createAsyncThunkWithLocalStorage} from "../helpers/reduxHelper";

export const getHomeFlashDeals = createAsyncThunk('home/flash_deals', fetchHomeFlashDeals)
export const getHomeTopCategories = createAsyncThunk('home/top_categories', fetchHomeTopCategories)
export const getHomeTopRatings = createAsyncThunk('home/top_ratings', fetchHomeTopRatings)
export const getHomeFeatureBands = createAsyncThunk('home/feature_bands', fetchHomeFeatureBrands)
export const getHomeNewArrivals = createAsyncThunk('home/new_arrivals', fetchHomeNewArrivals)
export const getHomeBigDiscounts = createAsyncThunk('home/big_discounts', fetchHomeBigDiscounts)
export const getHomeFeatureCategories = createAsyncThunkWithLocalStorage('home/feature_categories', fetchHomeFeatureCategories, 'feature_categories')
export const getMoreProducts = createAsyncThunk('home/more', fetchMoreForYou)
export const getHomeSliders = createAsyncThunkWithLocalStorage('home/sliders', fetchHomeSliders, 'sliders')
export const getHomeServices = createAsyncThunkWithLocalStorage('home/services', fetchHomeServices, 'services')
export const getHomeBanners = createAsyncThunkWithLocalStorage('home/banners', fetchBanners, 'banners')

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        sliders: [],
        products: [],
        offers: [],
        brands: [],
        categories: [],
        flashDeals: [],
        topCategories: [],
        topRatings: [],
        services: [],
        featureBands: [],
        featureCategories: [],
        newArrivals: [],
        bigDiscounts: [],
        more: [],
        banners: {},
    },
    reducers: {
        'sliders/fulfilled': (state, action) => {
            state.sliders = action.payload
        },
        'flash_deals/fulfilled': (state, action) => {
            state.flashDeals = action.payload.data
        },
        'top_categories/fulfilled': (state, action) => {
            state.topCategories = action.payload.data
        },
        'top_ratings/fulfilled': (state, action) => {
            state.topRatings = action.payload.data
        },
        'feature_bands/fulfilled': (state, action) => {
            state.featureBands = action.payload.data
        },
        'new_arrivals/fulfilled': (state, action) => {
            state.newArrivals = action.payload.data
        },
        'big_discounts/fulfilled': (state, action) => {
            state.bigDiscounts = action.payload.data
        },
        'feature_categories/fulfilled': (state, action) => {
            state.featureCategories = action.payload
        },
        'services/fulfilled': (state, action) => {
            state.services = action.payload
        },
        'banners/fulfilled': (state, action) => {
            state.banners = action.payload
        },
        'more/fulfilled': (state, action) => {
            state.more = action.payload.data
        },
    }
})
const homeReducer = homeSlice.reducer
export default homeReducer