import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchCart, fetchShippingCharge, postCart} from "../helpers/backend_helper";

export const getCart = createAsyncThunk('cart', async (auth= false) => {
    let localCart = localStorage.getItem('cart')
    if(localCart !== null) {
        let cart = JSON.parse(localCart)
        if(auth) {
            updateCartToLive(cart)
        }
        return cart
    }
    if(auth) {
       let {success, data} =await fetchCart()
        let products = data.map(product => ({...product, price: +product.price}))
        if(success === true) {
            localStorage.setItem('cart', JSON.stringify(products))
        }
        return products
    }
    return []
})
export const updateCheckout = createAsyncThunk('cart/checkout', async (data) => {
    let localCheckout = localStorage.getItem('checkout')
    if(localCheckout) {
        let checkout = JSON.parse(localCheckout)
        let newData =  {...checkout, ...data}
        localStorage.setItem('checkout', JSON.stringify(newData))
        return newData
    }
    localStorage.setItem('checkout', JSON.stringify(data))
    return data
})

export const getShippingCharge = createAsyncThunk('cart/shipping_charge', fetchShippingCharge)

export const updateCartToLive = async products => {
    let update = products.map(product => ({id: product.id, quantity: product.quantity}))
    await postCart({items: JSON.stringify(update)})
}


const cartSlice = createSlice({
    auth: false,
    name: 'cart',
    initialState: {
        products: [],
        checkout: {},
        shipping_charge: 0,
        sameBilling: false,
        discount: 0,
        voucher: {

        }
    },
    reducers: {
        auth: (state, action) => {
            state.auth = action.payload
        },
        fulfilled: (state, action) => {
            state.products = action.payload
        },
        toggleSameBilling: (state) => {
            localStorage.setItem('billing', !state.sameBilling ? 'same' : undefined)
            state.sameBilling = !state.sameBilling
        },
        setVoucher: (state, action) => {
            localStorage.setItem('voucher', JSON.stringify(action.payload))
            state.voucher = action.payload
        },
        loadCheckout: (state, ) => {
            state.sameBilling = localStorage.getItem('billing') === 'same'
            state.checkout = localStorage.getItem('checkout') ? JSON.parse(localStorage.getItem('checkout')) : {}
            state.voucher = localStorage.getItem('voucher') ? JSON.parse(localStorage.getItem('voucher')) : {}
        },
        'checkout/fulfilled': (state, action) => {
            state.checkout = action.payload
        },
        'shipping_charge/fulfilled': (state, action) => {
            state.shipping_charge = action.payload.data?.shipping_charge || 0
        },
        update: (state, action) => {
            let product = action.payload
            const products = JSON.parse(JSON.stringify(state.products));
            let index = products.findIndex(item => item.id === product.id)
            if(index > -1) {
                products[index].quantity = product.quantity
            } else {
                products.push(product)
            }
            if(state.auth === true) {
                updateCartToLive(products)
            }
            let update = products.filter(product => product.quantity > 0)
            localStorage.setItem('cart', JSON.stringify(update))
            state.products = update
        }
    }
})
const cartReducer = cartSlice.reducer
export default cartReducer