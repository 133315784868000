import {createSlice} from "@reduxjs/toolkit";
import {fetchAllBands} from "../helpers/backend_helper";
import {createAsyncThunkWithLocalStorage} from "../helpers/reduxHelper";

export const getAllBands = createAsyncThunkWithLocalStorage('brand', fetchAllBands, 'brands')

const brandSlice = createSlice({
    name: 'brand',
    initialState: {
        brands: []
    },
    reducers: {
        fulfilled: (state, action) => {
            state.brands = action.payload
        }
    }
})
const brandReducer = brandSlice.reducer
export default brandReducer