import {createSlice} from "@reduxjs/toolkit";
import {fetchCategories} from "../helpers/backend_helper";
import {createAsyncThunkWithLocalStorage} from "../helpers/reduxHelper";

export const getCategories = createAsyncThunkWithLocalStorage('category', fetchCategories, 'categories')

const categorySlice = createSlice({
    name: 'category',
    initialState: {
        categories: []
    },
    reducers: {
        fulfilled: (state, action) => {
            state.categories = action.payload
        }
    }
})
const categoryReducer = categorySlice.reducer
export default categoryReducer