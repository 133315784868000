import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { AppProvider } from '@context/app/AppContext';
import { GlobalStyles } from '@utils/globalStyles';
import { theme } from '@utils/theme';
import {Provider} from "react-redux";
import store from "@store/rootStore";
import {NotificationContainer} from 'react-notifications';
import '@fortawesome/fontawesome-free/css/all.css'
import 'react-notifications/lib/notifications.css';
import 'tailwindcss/tailwind.css'

//Binding events.
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

NProgress.configure({ showSpinner: false });

const App = ({ Component, pageProps }: any) => {
  let Layout = Component.layout || Fragment;

  return (
      <Provider store={store}>
          <ThemeProvider theme={theme}>
              <Head>
                  <meta name="viewport" content="width=device-width, initial-scale=1" />
                  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
                  <meta
                      property="og:url"
                      content="https://genx.ae"
                  />
                  {/* thumbnail And title for social media */}
                  <meta property="og:type" content="website" />
                  <meta property="og:title" content="Genx" />
                  <meta
                      property="og:description"
                      content="Genx - A digital marketplace"
                  />
                  <meta
                      property="og:image"
                      content="/assets/images/logo.png"
                  />
                  <title>GenX | Online Shopping</title>
              </Head>
              <GlobalStyles />
              <AppProvider>
                  <Layout>
                      <Component {...pageProps} />
                  </Layout>
                  <NotificationContainer/>
              </AppProvider>
          </ThemeProvider>
      </Provider>

  );
};

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// App.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default App;
