import {createAsyncThunk} from "@reduxjs/toolkit";
import moment from "moment";

export const createAsyncThunkWithLocalStorage = (typePrefix: string, payloadCreator, name, hour = false) => {
    return createAsyncThunk(typePrefix, async () => {
        let refreshDate = hour ? 'YYYY-MM-DD HH' : 'YYYY-MM-DD'
        let local = localStorage.getItem(name)
        if(local !== null) {
            let {date, data} = JSON.parse(local)
            if(date === moment().format(refreshDate)) {
                return data
            }
        }
        let {data: sliders} = await payloadCreator({})
        localStorage.setItem(name, JSON.stringify({date: moment().format(refreshDate), data: sliders}))
        return sliders
    })
}