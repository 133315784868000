import {configureStore} from "@reduxjs/toolkit";
import categoryReducer from "@store/categoryReducer";
import settingsReducer from "@store/settingsReducer";
import homeReducer from "@store/homeReducer";
import userReducer from "@store/userReducer";
import brandReducer from "@store/brandSReducer";
import cartReducer from "@store/cartReducer";

const store = configureStore({
    reducer: {
        cart: cartReducer,
        user: userReducer,
        home: homeReducer,
        brand: brandReducer,
        category: categoryReducer,
        settings: settingsReducer,
    },
    devTools: true,
})
export default store