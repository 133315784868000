import {createSlice} from "@reduxjs/toolkit";
import {fetchAboutUs, fetchSettings} from "../helpers/backend_helper";
import {createAsyncThunkWithLocalStorage} from "../helpers/reduxHelper";

export const getSettings = createAsyncThunkWithLocalStorage('settings', fetchSettings, 'settings')
export const getAboutUs = createAsyncThunkWithLocalStorage('settings/about', fetchAboutUs, 'about')
const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        data: {},
        about: []
    },
    reducers: {
        fulfilled: (state, action) => {
            state.data = action.payload.reduce((settings, data) => {
                settings[data.slug] = data.value
                return settings
            }, {})
        },
        'about/fulfilled': (state, action) => {
            state.about = action.payload
        }
    }
})
const settingsReducer = settingsSlice.reducer
export default settingsReducer