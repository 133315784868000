import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchAddresses, fetchCountries, fetchProfile, fetchUser, fetchWishList} from "helpers/backend_helper";
import {createAsyncThunkWithLocalStorage} from "helpers/reduxHelper";

export const getUser = createAsyncThunk('user', fetchUser)
export const getProfile = createAsyncThunk('user/profile', fetchProfile)
export const getAddresses = createAsyncThunk('user/addresses', fetchAddresses)
export const getWishlist = createAsyncThunk('user/wishlist', fetchWishList)
export const getCountries = createAsyncThunkWithLocalStorage('user/countries', fetchCountries, 'countries')

const userSlice = createSlice({
    name: 'user',
    initialState: {
        auth: false,
        name: "",
        email: "",
        phone_number: "",
        addresses: [],
        orders: [],
        wishlist: [],
        dob: "",
        gender: "",
        image: "",
        countries: [],
        coupons: [],
    },
    reducers: {
        fulfilled: (state, action) => {
            let {success, data} = action.payload
            if (success === true) {
                state.auth = true
                state.name = data.name
                state.image = data.image
            }
        },
        'profile/fulfilled': (state, action) => {
            let {success, data} = action.payload
            if (success === true) {
                state.email = data.email
                state.addresses = data.address
                state.orders = data.orders || []
                state.wishlist = data.wishlists || []
                state.phone_number = data.phone_number
                state.gender = data.gender
                state.dob = data.dob
                state.image = data.image
                state.coupons = data.coupons
            }
        },
        'countries/fulfilled': (state, action) => {
            state.countries = action.payload
        },
        'wishlist/fulfilled': (state, action) => {
            state.wishlist = action.payload.data
        },
        'addresses/fulfilled': (state, action) => {
            state.addresses = action.payload.data
        },
        logout: state => {
            state.auth = false
            state.name = ""
            state.email = ""
            state.addresses = []
            state.orders = []
            state.wishlist = []
            state.phone_number = ""
            state.gender = ""
            state.dob = ""
            state.image = ""
        }
    }
})
const userReducer = userSlice.reducer
export default userReducer