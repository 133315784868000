import {get, post} from "./api_helper";

export const fetchSettings = data => get('/settings', data)
export const fetchHomeSliders = data => get('/sliders', data)
export const fetchHomeFlashDeals = data => get('/flash-deals', data)
export const fetchHomeTopCategories = data => get('/top-categories', data)
export const fetchHomeTopRatings = data => get('/top-ratings', data)
export const fetchHomeFeatureBrands = data => get('/featured-brands', data)
export const fetchHomeNewArrivals = data => get('/new-arrivals', data)
export const fetchHomeFeatureCategories = data => get('/featured-categories', data)
export const fetchHomeBigDiscounts = data => get('/big-discounts', data)
export const fetchHomeServices = data => get('/services', data)
export const fetchCategories = data => get('/categories', data)
export const fetchProductDetails = slug => get('/product/' + slug , {})
export const fetchMoreForYou =  data => get('/more-for-you' , data)
export const fetchProducts = data => get('/products', data)
export const fetchCategoryBands = slug => get('/category-brands/' + slug, {})
export const fetchBandCategories = slug => get('/brand-categories/' + slug, {})
export const fetchBanners = data => get('/banners', data)

export const fetchAllBands = data => get('/all-brands' , data)


export const postLogin = data => post('/login', data)
export const fetchUser = data => get('/user', data)
export const postRegister = data => post('/signup', data)
export const postOtp = data => post('/otp-send', data)
export const postForgetPassword = data => post('/forget-password', data)
export const postPasswordReset = data => post('/password-reset', data)

export const fetchProfile = data => get('/profile', data)
export const postProfile = data => post('/profile', data)
export const postProfileImage = data => post('/profile-pic-upload', data)

export const fetchWishList = data => get('/wishlists', data)
export const postWishList = data => post('/wishlists', data)
export const fetchCart = data => get('/carts', data)
export const postCart = data => post('/carts', data)


export const fetchCountries = data => get('/countries', data)
export const fetchAddresses = data => get('/addresses', data)
export const postAddress = data => post('/address', data)
export const postAddressDelete = data => post('/address-remove', data)

export const fetchShippingCharge = data => post('/shipping-charge', data)
export const fetchShippingProviders = data => get('/shipping-providers', data)
export const postVoucher = data => post('/apply-coupon', data)

export const postOrder = data => post('/order-place', data)
export const fetchOrder = id => get('/order-details/' + id, {})
export const postReview = data => post('/reviews', data)

export const postStripePayment = data => post('/stripe-payment', data)
export const postPaypalPayment = data => post('/paypal-payment', data)
export const postCODPayment = data => post('/cod-payment', data)


export const postSellOnGenx = data => post('/sell-on-genx', data)

export const contactUs = data => post('/contact-us', data)
export const fetchAboutUs = data => get('/about-us?content=true', data)